<template>
  <div class="content-area-container flex-c">
    <div class="content-box">
      <div class="content-area flex">
        <div class="content-info">
          <div class="course-info">
            <div class="course-intro ellipsis-2">
              <span
                v-if="course.contractStatus.contractId"
                class="course-style course-agreement"
                >协议课</span
              >
              <span v-if="course.subject" class="course-style">{{
                course.subject
              }}</span>
              {{ course.courseName }}
            </div>
            <p class="course-propmt flex-h-c">
              <i class="icon-time"></i>
              <span class="course-time">{{ course.courseTime }}</span>
              <span v-if="course.lessonCnt" class="dot">·</span>
              <span v-if="course.lessonCnt" class="course-num"
                >共{{ course.lessonCnt }}节</span
              >
            </p>
            <div
              v-if="teacherInfo && teacherInfo.length"
              class="teacher-avator flex"
            >
              <dl
                v-for="item in teacherInfo"
                :key="item.teacherUid"
                class="teacher-info"
              >
                <dt>
                  <img
                    :onerror="userDeafaultAvator"
                    :src="handlePic(item.teacherAvatar)"
                  />
                </dt>
                <dd class="ellipsis-1">{{ item.teacherName }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="qrcode-container flex-hv-c">
          <img :src="assistant.qrcode" alt="" />
          <p>微信扫描二维码添加老师</p>
          <p>享受更多专属服务</p>
        </div>
      </div>
      <div class="lesson-list-container">
        <h3 class="lesson-title flex-h-c">课程列表</h3>
        <ul v-if="!course.isTiled" class="lesson-list-content">
          <LessonItem
            v-for="item in chapter"
            :key="item.chapterInfo"
            :chapter="item"
            :isFreeCourse="course.isFreeCourse"
            :lessonStatusMap="lessonStatusMap"
            @goScrollTop="goScrollTop"
            @jumpToNextPage="jumpToNextPage"
          />
        </ul>
        <ul v-if="course.isTiled" class="lesson-list-content">
          <LessonItemTitled
            v-for="item in filterLessionList"
            :key="item.lessonId"
            :isFreeCourse="course.isFreeCourse"
            :lesson="item"
            :lessonStatusMap="lessonStatusMap"
            @goScrollTop="goScrollTop"
            @jumpToNextPage="jumpToNextPage"
          />
        </ul>
      </div>
    </div>
    <SideBar />
  </div>
</template>

<script>
import { zs } from "@zyb-data/stats-h5";
import {
  LESSONSTATUS_MAP,
  PLAYBACK_MAP,
  PLAYBACK_TYPE_LIST,
  LIVE_PLAYBACK_TYPE_LIST
} from "@/utils/constant";
import { goLive, resizeImage } from "@/utils/util";
import * as APICOLLECTION from "@/api/my.js";

import LessonItem from "./LessonItem.vue";
import LessonItemTitled from "./LessonItemTitled.vue";
import SideBar from "../SideBar.vue";

import userDeafaultAvator from "../../assets/default-avator.png";

function getLessonStatusObj(
  living = false,
  text = "",
  className = "btn-inactive"
) {
  return {
    living,
    text,
    className
  };
}

const lessonStatusMap = {
  1: getLessonStatusObj(false, "未开始"),
  2: getLessonStatusObj(true, "进教室", "btn-active"),
  3: getLessonStatusObj(true, "回放生成中"),
  4: getLessonStatusObj(false, "看视频", "btn-default"),
  5: getLessonStatusObj(false, "无回放"),
  8: getLessonStatusObj(false, "看回放", "btn-default"),
  6: getLessonStatusObj(false, "已失效"), // 已重开
  7: getLessonStatusObj(false, "已失效")
};

export default {
  props: {
    courseDetail: {
      type: Object,
      default: () => {}
    },
    courseId: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    teacherInfo() {
      const teacherArr = Object.assign([], this.teacher);
      return teacherArr.splice(0, 6);
    },
    filterLessionList() {
      if (!this.chapter[0].lessonList) return [];
      let index = 0;
      return this.chapter[0].lessonList.map(item => {
        if (!item.isReopen) index += 1;
        return {
          ...item,
          index
        };
      });
    }
  },
  mounted() {
    zs.track("DNF_001", {
      courseID: this.courseId
    });
  },
  data() {
    return {
      course: this.courseDetail.course,
      chapter: this.courseDetail.chapter,
      assistant: this.courseDetail.assistant,
      teacher: this.courseDetail.teacher,
      lessonStatusMap,
      userDeafaultAvator: `this.src="${userDeafaultAvator}"`
    };
  },
  components: {
    LessonItem,
    LessonItemTitled,
    SideBar
  },
  methods: {
    jumpToNextPage(data) {
      /**
       * playType: 1直播，2伪直播，3录播
       * lessonStatus: 章节状态，1未开始，2直播中，3生成回放中，4回放已生成，5无回放
       * userLessonStatus: 1是已退款
       * isReopen: bool值是否重开
       * lessonStatus为1的时候，按钮状态‘未开始’
       * lessonStatus为2且playType为1，按钮状态叫‘进教室’
       * lessonStatus为2且playType为2或者3,按钮状态叫‘看视频’
       * lessonStatus为5，按钮状态叫‘无回放’
       * lessonStatus为3，回放生成中
       * lessonStatus为4且playType为1，看回放
       */

      /**
       * lessonStatus === 2 && playType === 1 这是直播课，此时按钮文案‘进教室’
       * lessonStatus === 4 && playType === 1 这是回放课，此时按钮文案‘看回放’
       * lessonStatus === 4 && playType === 2 这是伪直播，此时按钮文案‘看视频’
       * lessonStatus === 4 && playType === 3 这是录播课，此时按钮文案‘看视频’
       * lessonStatus === 2 && playType === 2 这是伪直播，此时按钮文案‘看视频’
       * lessonStatus === 2 && playType === 3 这是录播课，此时按钮文案‘看视频’
       *
       */
      const {
        lessonStatus,
        playType,
        lessonId,
        courseId,
        userLessonStatus,
        isReopen
      } = data;
      const { isFreeCourse } = this.course;
      if (userLessonStatus || isReopen) {
        return;
      }
      if (
        lessonStatus !== LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        lessonStatus !== LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"]
      ) {
        return;
      }
      this.zLog(data, {
        courseId,
        lessonId
      });
      if (!isFreeCourse) {
        this.setLatestLesson({
          lessonId,
          courseId
        });
      }
      let playback = null; // 0 是直播课 1是回放课/录播课
      if (
        lessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        playType === PLAYBACK_MAP["PLAYTYPE_LIVE"]
      ) {
        playback = 0;
      }
      // 课程状态回放已完成下，回放课，伪直播，录播课，进回放间
      if (
        lessonStatus === LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"] &&
        LIVE_PLAYBACK_TYPE_LIST.indexOf(playType) !== -1
      ) {
        playback = 1;
      }
      // 课程状态为直播课，伪直播, 录播课 进回放间
      if (
        lessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        PLAYBACK_TYPE_LIST.indexOf(playType) !== -1
      ) {
        playback = 1;
      }
      goLive({
        courseId,
        lessonId,
        playback
      });
    },
    zLog(data = {}, params) {
      const { lessonStatus, playType } = data;
      if (
        lessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        playType === PLAYBACK_MAP["PLAYTYPE_LIVE"]
      ) {
        zs.track("DNF_004", params);
      } else if (
        lessonStatus === LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"] &&
        playType === PLAYBACK_MAP["PLAYTYPE_LIVE"]
      ) {
        zs.track("DNF_003", params);
      } else if (
        lessonStatus === LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"] &&
        (playType === PLAYBACK_MAP["PLAYTYPE_PLAYBACK"] ||
          playType === PLAYBACK_MAP["PLAYTYPE_RECORDLESSON"])
      ) {
        zs.track("DNF_002", params);
      }
    },
    goScrollTop(ele) {
      if (!ele) return;
      const scrollTop = ele.offsetTop || 0;
      try {
        window.scrollTo({
          top: scrollTop,
          behavior: "smooth"
        });
      } catch {
        window.scrollTo(0, scrollTop);
      }
    },
    setLatestLesson(params) {
      APICOLLECTION.setLatestLesson(params);
    },
    handlePic(data) {
      return resizeImage(data, 96, 96);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.content-area-container {
  min-height: calc(100vh - 160px);
  background-color: @color-light-white;
}

.content-box {
  flex-grow: 1;
  position: relative;
  width: 1180px;
  margin: 0 auto 0;
}

.content-area {
  margin-top: 128px;
}

.content-info {
  width: 980px;
  padding: 28px;
  box-sizing: border-box;
  background-color: @color-white;
  border-radius: @border-radius-normal;

  .course-intro {
    font-size: @font-size-huge;
    color: @color-xlight-black;
    line-height: 25px;
    font-weight: @font-weight-bold;
    font-family: @font-family-bold;
    vertical-align: middle;
  }

  .course-style {
    display: inline-block;
    padding: 3px 5px;
    margin: -3px 4px 0 0;
    color: @color-white;
    line-height: @font-size-small;
    font-size: @font-size-small;
    font-weight: @font-weight-normal;
    background-color: @color-big-highlight;
    border-radius: 2px 5px 2px 5px;
    vertical-align: middle;
  }

  .course-agreement {
    background-color: @color-blue;
  }

  .course-propmt {
    margin: 4px 0 36px;
    color: @color-small-black;
    font-family: @font-family-regular;

    .icon-time {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 4px;
      background: url(../../assets/icon-time.png) no-repeat 0 0 / cover;
    }

    .course-time,
    .course-num {
      font-size: @font-size-base;
      line-height: 14px;
    }

    .dot {
      padding: 0 8px;
    }
  }

  .teacher-info {
    width: 52px;
    margin-right: 12px;

    dt {
      width: 52px;
      height: 52px;
      margin-bottom: 2px;
    }

    img {
      width: 52px;
      height: 52px;
      border-radius: @border-radius-circle;
      background-color: @color-small-white;
    }

    dd {
      width: 100%;
      text-align: center;
      font-size: @font-size-small;
      color: @color-grey;
      font-family: @font-family-regular;
    }
  }
}

.qrcode-container {
  width: 180px;
  height: 222px;
  margin-left: 20px;
  text-align: center;
  border-radius: @border-radius-normal;
  background-color: @color-white;

  p {
    margin-bottom: 4px;
    color: @color-xlight-black;
    font-size: @font-size-small;
    font-weight: @font-weight-bold;
    font-family: @font-family-bold;
  }

  img {
    width: 117px;
    height: 117px;
    margin-bottom: 14px;
  }
}

.lesson-list-container {
  width: 980px;
  margin: 8px 0 32px;
  background-color: @color-white;
  box-shadow: 0px 4px 8px 0px rgba(63, 64, 76, 0.02);
  border-radius: @border-radius-normal;
}

.lesson-title {
  margin: 0;
  padding: 20px 28px 20px;
  font-size: @font-size-huge;
  color: @color-light-black;
  font-family: @font-family-bold;
  line-height: @font-size-huge;

  &:before {
    display: inline-block;
    content: "";
    width: 4px;
    height: 18px;
    margin-right: 4px;
    background-color: @color-highlight;
    border-radius: @border-radius-base;
  }
}
</style>

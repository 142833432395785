<template>
  <div
    :class="[
      'header',
      { 'header-fixed': !isLivePage, 'header-live': isLivePage }
    ]"
  >
    <div class="header-container flex-hv-s" :style="{ width: headerWidth }">
      <h1 :class="['title-text', { 'title-logo': !title }]">{{ title }}</h1>
      <div class="flex-h-c">
        <div
          :class="['link-content', { 'content-distance': isLoginPage }]"
          v-if="!isLivePage"
        >
          <a href="javascript:;" class="link-active">我的课程</a>
        </div>
        <div class="dropdown-bubble" v-if="!isLoginPage">
          <div class="header-user flex-hv-st">
            <img
              :src="avator"
              alt=""
              class="useravatar"
              :onerror="userDefaultErrorImg"
            />
            <span class="username ellipsis-1">{{ userInfo.uname }}</span>
            <div class="icon-arrow"></div>
          </div>
          <ul class="dropdown-menu">
            <li @click="handlelogout" class="zs_track_logout_click flex-h-c">
              <div class="icon-loginout"></div>
              <span>退出登录</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as APICOLLECTION from "@/api/my";

import { zs } from "@zyb-data/stats-h5";
import { goLogin } from "@/utils/util";

import userDeafaultAvator from "./assets/default-avator.png";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    videoSize: {
      type: Object,
      default: () => {}
    },
    isLoginPage: {
      type: Boolean,
      default: () => false
    },
    isLivePage: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      userInfo: {}
    };
  },
  async mounted() {
    if (!this.isLoginPage) {
      const res = await APICOLLECTION.getUserInfo();
      const { errNo, data } = res;
      if (!errNo) {
        this.userInfo = data;
      }
    }
  },
  computed: {
    headerWidth() {
      if (this.videoSize?.liveWidth) {
        return `${this.videoSize.liveWidth}px`;
      }
      return "1180px";
    },
    avator() {
      if (this.userInfo && this.userInfo.avatar) {
        return this.userInfo.avatar;
      }
      return userDeafaultAvator;
    },
    userDefaultErrorImg() {
      return `this.src="${userDeafaultAvator}"`;
    }
  },
  methods: {
    async handlelogout() {
      zs.track("DNE_001");
      try {
        await APICOLLECTION.logout();
        goLogin();
      } catch (err) {
        console.error("登出失败");
      }
    }
  }
};
</script>

<style scoped lang="less">
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.header-fixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
}
.header {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  background-color: @color-white;
  .title-text {
    color: @color-xxhuge-white;
    font-size: @font-size-large;
  }
  .title-logo {
    width: 85px;
    height: 22px;
    background: url(./assets/logo.png) no-repeat 0 0 / cover;
  }
  &-user {
    margin: 0;
    padding: 16px 0;
  }

  &-container {
    margin: 0 auto;
  }
}
.header-user {
  width: 132px;
  .useravatar {
    width: 26px;
    height: 26px;
    border-radius: @border-radius-circle;
    border: 1px solid #e1e1e1;
  }
  .username {
    font-size: @font-size-base;
    font-weight: @font-weight-bold;
    color: @color-slight-black;
    line-height: 14px;
    margin-left: 8px;
    max-width: 80px;
    font-family: @font-family-bold;
  }
  .icon-arrow {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background: url(./assets/icon-arrow.png) no-repeat 0 0 / cover;
    transform: rotateX(180deg);
  }
}
.header-live {
  height: 40px;
  background-color: @color-light-black;
  .username {
    color: @color-xxhuge-white;
  }
  .header-user {
    .useravatar {
      width: 16px;
      height: 16px;
    }
  }
  .dropdown-menu {
    top: 40px;
  }
}
.header-container {
  width: 1180px;
  margin: 0 auto;
}
.link-content {
  a {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 60px;
    line-height: 60px;
    margin-right: 40px;
    text-align: center;
    color: @color-xxlight-balck;
    font-size: @font-size-base;
  }
  .link-active {
    color: @color-highlight;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      width: 42px;
      height: 4px;
      background-color: @color-highlight;
      border-radius: @border-radius-small;
    }
  }
}
.content-distance {
  margin-right: 120px;
}
.dropdown-bubble {
  position: relative;
  cursor: pointer;
}

.dropdown-bubble:hover .dropdown-menu,
.dropdown-bubble:active .dropdown-menu {
  opacity: 1;
  transition: opacity 0.4s linear;
}
.dropdown-bubble:hover .icon-arrow,
.dropdown-bubble:active .icon-arrow {
  transform: none;
}
.dropdown-menu {
  z-index: 100;
  opacity: 0;
  width: 120px;
  position: absolute;
  left: 0;
  top: 60px;
  background: @color-white;
  box-shadow: 0px 0px 16px 0px rgba(63, 64, 76, 0.12);
  border-radius: @border-radius-normal;
  li {
    padding: 10px 24px 10px 20px;
    cursor: pointer;
    .icon-loginout {
      width: 16px;
      height: 16px;
      background: url(./assets/icon-loginout.png) no-repeat 0 0 / cover;
    }
    span {
      margin-left: 4px;
      font-size: @font-size-base;
      color: @color-xlight-black;
      white-space: nowrap;
      font-family: @font-family-regular;
    }
  }
}
</style>

<template>
  <div class="course-detail-container" v-if="isLoading">
    <Header />
    <Link />
    <ContentArea :courseDetail="courseDetail" :courseId="courseId" />
    <Footer />
  </div>
</template>

<script>
import * as APICOLLECTION from "@/api/my.js";
import utilsFun from "@/common/js/utilsFun.js";
import { startLoading, endLoading } from "@/utils/util";

import Link from "../components/detail/Link.vue";
import Header from "../../../components/header/index.vue";
import Footer from "../components/footer.vue";
import ContentArea from "../components/detail/ContentArea.vue";

export default {
  data() {
    return {
      isLoading: false,
      active: false,
      courseId: "",
      courseName: "",
      courseDetail: {},
      latestLesson: "" // 上一次观看课程的id
    };
  },
  async created() {
    startLoading();
    this.courseId = this.$route.params.courseId - 0;
    await APICOLLECTION.latestLesson({ courseId: this.courseId })
      .then(res => {
        const { errNo, data, errStr } = res;
        if (!errNo) {
          this.latestLesson = data.latestLesson;
        } else {
          console.log(errStr, "请求上次观看课程ID报错");
        }
      })
      .catch(error => {
        throw new Error(error);
      });
    APICOLLECTION.getCourseDetail({ courseId: this.courseId })
      .then(res => {
        const { errNo, data, errStr } = res;
        if (!errNo) {
          this.courseName = data?.course?.courseName;
          this.courseDetail = data;
          const isFreeCourse = data.course?.isFreeCourse;
          this.courseDetail.chapter = this.courseDetail.chapter?.map(item => {
            item.lessonList?.map(data => {
              if (data.lessonId === this.latestLesson && !isFreeCourse) {
                item.chapterStatus = true;
                data.latestLesson = true;
              }
            });
            return item;
          });
          this.isLoading = true;
          endLoading();
        } else if (errNo !== 201002) {
          utilsFun.toastText(errStr);
          // 未报名跳转到我的课程页面
          if (errNo === 207011) {
            setTimeout(() => {
              this.$router.push({
                path: "/"
              });
            }, 3000);
          }
        }
      })
      .catch(error => {
        utilsFun.toastText(error || "网络错误，请稍候再试！");
      });
  },
  components: {
    Header,
    Link,
    Footer,
    ContentArea
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";

.course-detail-container {
  overflow: auto;
}
</style>

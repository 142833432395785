<template>
  <li class="lesson-item">
    <div class="lesson-content flex-r-sb" @click="handleToggleSubLesson">
      <div class="lesson-rank">第{{ chapter.chapterNo }}章</div>
      <div class="lesson-name">{{ chapter.chapterName }}</div>
      <div
        class="icon-arrow"
        :class="{ 'icon-up': subLessonShow, 'icon-down': !subLessonShow }"
      ></div>
    </div>
    <div class="chapter-content" v-show="subLessonShow">
      <div
        class="chapter-item flex-r-sb"
        :class="{ 'chapter-item-disabled': item.disabled }"
        v-for="item in filterLessionList"
        :key="item.lessonName"
        :ref="item.latestLesson && 'watchEle'"
      >
        <div class="chapter-rank" v-if="!item.isReopen">
          第{{ item.index }}节
        </div>
        <div
          class="chapter-right flex-c-sb"
          :class="{ 'chapter-right-padding': item.isReopen }"
        >
          <div class="chapter-part">
            <p class="chapter-name">{{ item.lessonName }}</p>
            <p class="chapter-info">
              <span v-if="item.latestLesson" class="latest-look">上次看到</span>

              {{ item.lessonText }}{{ item.lessonTime }}
              <span v-if="!isFreeCourse">{{ item.lessonComplete }}</span>
            </p>
          </div>
          <div
            class="chapter-btn"
            :class="getLessonStatus(item).className"
            @click="handleGoJump(item)"
          >
            <span class="icon-live" v-if="item.isLive"></span>
            {{ getLessonStatus(item).text }}
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { LESSONSTATUS_MAP, PLAYBACK_MAP } from "@/utils/constant";

export default {
  props: {
    chapter: {
      type: Object,
      default: () => {}
    },
    lessonStatusMap: {
      type: Object,
      default: () => {}
    },
    isFreeCourse: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      subLessonShow: false
    };
  },
  mounted() {
    this.subLessonShow = !!this.chapter.chapterStatus;
    this.$nextTick(() => {
      const ele = this.$refs.watchEle ? this.$refs.watchEle[0] : null;
      this.$emit("goScrollTop", ele);
    });
  },
  computed: {
    filterLessionList() {
      if (!this.chapter.lessonList) return [];
      let index = 0;
      return this.chapter.lessonList.map(item => {
        if (!item.isReopen) index += 1;
        if (!item.lessonCompleteRate) {
          item.lessonComplete = ` · 未学习`;
        } else {
          item.lessonComplete =
            item.lessonCompleteRate >= 99
              ? " · 已学完"
              : ` · 已学习${item.lessonCompleteRate}%`;
        }
        if (
          !item.isReopen &&
          item.lessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"]
        ) {
          item.lessonText = "直播中 · ";
        } else if (item.isReopen) {
          item.lessonText = "已重开 · ";
        } else {
          item.lessonText = "";
        }
        item.isLive =
          !item.isReopen &&
          item.lessonStatus ===
            LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
          item.playType === PLAYBACK_MAP["PLAYTYPE_LIVE"] &&
          !item.userLessonStatus;
        // 未开始，回放生成中，无回放，已重开，已失效-禁用状态
        item.disabled =
          item.userLessonStatus ||
          item.isReopen ||
          item.lessonStatus ===
            LESSONSTATUS_MAP["LIVENOTSTART_LESSON_STATUS"] ||
          item.lessonStatus ===
            LESSONSTATUS_MAP["VIDEOCREATING_LESSON_STATUS"] ||
          item.lessonStatus === LESSONSTATUS_MAP["NOPLAYBACK_LESSON_STATUS"];
        return {
          ...item,
          index
        };
      });
    }
  },
  methods: {
    handleToggleSubLesson() {
      this.subLessonShow = !this.subLessonShow;
    },
    getLessonStatus(lesson) {
      /**
       * playType: 1直播，2伪直播，3录播
       * lessonStatus: 章节状态，1未开始，2直播中，3生成回放中，4回放已生成，5无回放
       * userLessonStatus: 1是已退款
       * isReopen: bool值是否重开
       * lessonStatus为1的时候，按钮状态‘未开始’
       * lessonStatus为2且playType为1，按钮状态叫‘进教室’
       * lessonStatus为2且playType为2或者3,按钮状态叫‘看视频’
       * lessonStatus为5，按钮状态叫‘无回放’
       * lessonStatus为3，回放生成中
       * lessonStatus为4且playType为1，看回放
       * lessonStatus为4且playType为2，看视频
       * lessonStatus为4且playType为3，看视频
       */
      const { lessonStatus, userLessonStatus, isReopen, playType } = lesson;
      let newLessonStatus = lessonStatus;
      // 看回放
      if (
        newLessonStatus === LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"] &&
        playType === PLAYBACK_MAP["PLAYTYPE_LIVE"]
      )
        newLessonStatus = LESSONSTATUS_MAP["PLAYBACK_LESSON_STATUS"];
      // 进教室
      if (
        newLessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        playType === PLAYBACK_MAP["PLAYTYPE_LIVE"]
      )
        newLessonStatus = LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"];
      // 看视频
      if (
        newLessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        (playType === PLAYBACK_MAP["PLAYTYPE_PLAYBACK"] ||
          playType === PLAYBACK_MAP["PLAYTYPE_RECORDLESSON"])
      )
        newLessonStatus = LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"];
      if (
        newLessonStatus === LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"] &&
        (playType === PLAYBACK_MAP["PLAYTYPE_PLAYBACK"] ||
          playType === PLAYBACK_MAP["PLAYTYPE_RECORDLESSON"])
      )
        newLessonStatus = LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"];
      if (userLessonStatus)
        newLessonStatus = LESSONSTATUS_MAP["EXPIRED_LESSON_STATUS"];
      if (isReopen)
        newLessonStatus = LESSONSTATUS_MAP["REOPENED_LESSON_STATUS"];
      return this.lessonStatusMap[newLessonStatus];
    },
    handleGoJump(item) {
      this.$emit("jumpToNextPage", item);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.lesson-item {
  margin: 0 32px;
  border-bottom: 1px solid @color-xxxlight-white;
  font-family: @font-family-regular;
}
.lesson-content {
  padding: 24px 0;
  .lesson-rank {
    flex-shrink: 0;
    margin-right: 32px;
    font-size: @font-size-large;
    color: @color-slight-black;
  }
  .lesson-name {
    flex-grow: 1;
    margin-right: 200px;
    font-size: @font-size-large;
    columns: @color-xlight-black;
    font-weight: @font-weight-bold;
    font-family: @font-family-bold;
  }
  .icon-arrow {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
  .icon-up {
    background: url(../../assets/icon-up.png) no-repeat 0 0 / cover;
  }
  .icon-down {
    transform: rotateX(180deg);
    background: url(../../assets/icon-up.png) no-repeat 0 0 / cover;
  }
}
.chapter-content {
  margin: 0 -32px;
  padding: 0 40px 0 103px;
  background-color: @color-xxlight-white;
}
.chapter-item {
  padding: 24px 0;
  border-bottom: 1px solid @color-small-white;
  .chapter-rank {
    flex-shrink: 0;
    min-width: 48px;
    margin-right: 30px;
    font-size: @font-size-large;
    color: @color-slight-black;
  }
  .chapter-right {
    flex-grow: 1;
  }
  .chapter-right-padding {
    padding-left: 78px;
  }
  &:last-child {
    border: none;
  }
}
.chapter-part {
  margin-right: 200px;
  .chapter-name {
    font-size: @font-size-large;
    line-height: @font-size-large;
    font-weight: @font-weight-bold;
    color: @color-light-black;
    font-family: @font-family-bold;
  }
  .chapter-info {
    margin-top: 8px;
    color: @color-xlight-gray;
    font-size: @font-size-base;
    line-height: 14px;
  }
}
.icon-live {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(../../assets/live.gif) no-repeat 0 0 / cover;
}
.chapter-btn {
  flex-shrink: 0;
  width: 100px;
  height: 36px;
  line-height: 36px;
  font-size: @font-size-base;
  text-align: center;
  border-radius: @border-radius-large;
  background-color: transparent;
  cursor: pointer;
  font-family: @font-family-bold;
  &.btn-active {
    color: @color-white;
    background: linear-gradient(153deg, #ff6a73 0%, #f5303c 100%);
  }
  &.btn-default {
    color: @color-highlight;
    background: rgba(246, 48, 60, 0.05);
  }
  &.btn-inactive {
    color: @color-xlight-gray;
    background-color: @color-small-white;
  }
}
.latest-look {
  padding: 1px 4px;
  margin-right: 8px;
  font-size: @font-size-small;
  color: @color-slight-highlight;
  background-color: rgba(255, 126, 60, 0.1);
  border-radius: @border-radius-small;
}
.chapter-item-disabled {
  .chapter-rank,
  .chapter-name,
  .chapter-info {
    color: @color-sslight-gray;
  }
  .chapter-btn {
    cursor: not-allowed;
  }
}
</style>

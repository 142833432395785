import Vue from "vue";
import VueRouter from "vue-router";

import CourseList from "../views/CourseList.vue";
import CourseDetail from "../views/CourseDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "CourseList",
    component: CourseList
  },
  {
    path: "/courseDetail/:courseId",
    name: "CourseDetail",
    component: CourseDetail
  }
];

const router = new VueRouter({
  routes
});

export default router;

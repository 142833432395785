<template>
  <div class="page-container flex-v-c" v-if="pageNum">
    <ul class="page-content flex-h-r">
      <li>
        <span
          :class="[isLastDisabled ? 'icon-last-disabled' : 'icon-last']"
          @click="handleGoLastPage"
        ></span>
      </li>
      <li
        v-for="item in pageNumArr"
        :key="item.index"
        :class="[{ active: item.index === pageNo }]"
        @click="handlePage(item.index)"
      >
        {{ item.value }}
      </li>
      <li>
        <span
          :class="[isNextDisabled ? 'icon-next-disabled' : 'icon-next']"
          @click="handleGoNextPage"
        ></span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { startLoading } from "@/utils/util";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      total: "total",
      pageSize: "pageSize",
      pageNo: "pageNo"
    }),
    pageNum() {
      return Math.ceil(this.total / this.pageSize);
    },
    pageNumArr() {
      const arr = [];
      for (let i = 1; i <= this.pageNum; i++) {
        arr.push({
          index: i,
          value: i
        });
      }
      return arr;
    },
    isLastDisabled() {
      return this.pageNo === 1;
    },
    isNextDisabled() {
      return this.pageNo === this.pageNum;
    }
  },
  methods: {
    ...mapActions({
      getCourseList: "getCourseList"
    }),
    ...mapMutations({
      setPageNo: "SET_PAGENO",
      setIsLoading: "SET_ISLOADING"
    }),
    async handlePage(pageNo) {
      if (pageNo === this.pageNo) {
        return;
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      startLoading(".list-container");
      this.setPageNo(pageNo);
      this.setIsLoading(false);
      await this.getCourseList({
        pageNo
      });
    },
    handleGoLastPage() {
      if (this.isLastDisabled) {
        return;
      }
      this.handlePage(this.pageNo - 1);
    },
    handleGoNextPage() {
      if (this.isNextDisabled) {
        return;
      }
      this.handlePage(this.pageNo + 1);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.page-container {
  padding: 12px 0 87px;
}
.page-content {
  li {
    width: 40px;
    height: 40px;
    margin-right: 6px;
    line-height: 40px;
    text-align: center;
    font-size: @font-size-base;
    color: @color-slight-gray;
    font-family: @font-family-bold;
    background-color: @color-white;
    border-radius: @border-radius-normal;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
    .icon-last {
      transform: rotateY(180deg);
      background: url(../../assets/icon-active.png) no-repeat 0 0 / cover;
    }
    .icon-next {
      background: url(../../assets/icon-active.png) no-repeat 0 0 / cover;
    }
    .icon-last-disabled {
      cursor: not-allowed;
      background: url(../../assets/icon-disabled.png) no-repeat 0 0 / cover;
    }
    .icon-next-disabled {
      cursor: not-allowed;
      transform: rotateY(180deg);
      background: url(../../assets/icon-disabled.png) no-repeat 0 0 / cover;
    }
  }
  .active {
    background-color: @color-highlight;
    color: @color-white;
  }
}
</style>

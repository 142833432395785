import request from "../common/js/request.js";

export const getUserInfo = (params = {}) => {
  return request.get("/adupc/v1/user/info", params);
};

export const getCourseList = (params = {}) => {
  return request.post("/adupc/usercourse/list", params);
};

export const getCourseDetail = (params = {}) => {
  return request.post("/adupc/usercourse/detail", params);
};

// 用户登出
export const logout = (params = {}) => {
  return request.post("/adupc/user/logout", params);
};

// 记录上次观看的位置
export const setLatestLesson = (params = {}) => {
  return request.post("/adupc/v1/latestlesson/set", params);
};

// 获取记录用户上次观看的位置
export const latestLesson = (params = {}) => {
  return request.post("/adupc/v1/latestlesson/detail", params);
};

function toastText(text) {
  //toast提示
  let toastTimer;
  let yikeLogToast = document.getElementById("yike-log-toast");
  if (yikeLogToast) {
    if (toastTimer) {
      clearTimeout(toastTimer);
    }
    document.body.removeChild(yikeLogToast);
  }
  let toastHtml = document.createElement("div");
  toastHtml.id = "yike-log-toast";
  toastHtml.innerHTML = `
      <div style="position:fixed;z-index:999; left: 50%; top: 50%;transform:translate(-50%, -50%);padding: 8px 16px;background-color: #000;border-radius: 4px;font-size: 14px;font-family:PingFangSC-Regular,PingFangSC;font-weight:400;color: rgba(255,255,255,1);text-align:center;">${text}</div>
    `;
  document.body.appendChild(toastHtml);
  toastTimer = setTimeout(function() {
    document.body.removeChild(toastHtml);
  }, 2000);
}

export default {
  toastText
};

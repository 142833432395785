import Vue from "vue";
import Vuex from "vuex";

import my from "./modules/my";

// getters
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    my
  },
  getters
});

export default store;

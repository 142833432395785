<template>
  <li
    class="course-item fl"
    :class="{
      'course-item-disabled': course.isExpired,
      'course-item-hover': !course.isExpired
    }"
    @click="handleClickItem"
  >
    <div class="course-intro ellipsis-2">
      <span
        class="course-style course-agreement"
        v-if="course.contractStatus.contractId"
        >协议课</span
      >
      <span class="course-style" v-if="course.subject">{{
        course.subject
      }}</span>
      {{ course.courseName }}
    </div>
    <p class="course-info flex-h-c">
      <i class="icon-time"></i>
      <span class="course-time">{{ course.courseTime }}</span>
      <span class="dot" v-if="course.lessonCnt">·</span>
      <span class="course-num" v-if="course.lessonCnt"
        >共{{ course.lessonCnt }}节</span
      >
    </p>
    <div
      class="teacher-avator flex"
      v-if="course.teacherInfo && course.teacherInfo.length"
    >
      <dl
        class="teacher-info"
        v-for="item in teacherInfo"
        :key="item.teacherUid"
      >
        <dt>
          <img
            :src="handlePic(item.teacherAvatar)"
            :onerror="userDeafaultAvator"
          />
        </dt>
        <dd class="ellipsis-1">{{ item.teacherName }}</dd>
      </dl>
    </div>
    <div class="overdue" v-if="course.isExpired"></div>
  </li>
</template>

<script>
import { zs } from "@zyb-data/stats-h5";
import { resizeImage } from "@/utils/util";
import IconPosition from "../../assets/icon-position.png";
import userDeafaultAvator from "../../assets/default-avator.png";

export default {
  props: {
    course: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      iconPosition: IconPosition,
      courseErrorImg: `this.src="${IconPosition}"`,
      userDeafaultAvator: `this.src="${userDeafaultAvator}"`
    };
  },
  computed: {
    teacherInfo() {
      const teacherArr = Object.assign([], this.course.teacherInfo);
      return teacherArr.splice(0, 5);
    }
  },
  methods: {
    handleGoCourseDetail(name) {
      if (this.course.isExpired) {
        return;
      }
      const courseId = this.course.courseId;
      zs.track(name, {
        courseID: courseId
      });
      this.$router.push({
        path: `/courseDetail/${courseId}`
      });
    },
    handleClickItem() {
      this.handleGoCourseDetail("DNE_004");
    },
    handlePic(data) {
      return resizeImage(data);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.course-item {
  position: relative;
  width: 380px;
  height: 277px;
  padding: 20px;
  margin: 0 20px 20px 0;
  box-shadow: 0px 4px 8px 0px rgba(63, 64, 76, 0.02);
  box-sizing: border-box;
  background-color: @color-white;
  border-radius: @border-radius-normal;
  transition: all 0.2s linear;
  cursor: pointer;
  .course-cover {
    width: 248px;
    height: 139px;
    object-fit: contain;
  }
  .course-intro {
    font-size: @font-size-huge;
    color: @color-xlight-black;
    line-height: 30px;
    font-weight: @font-weight-bold;
    font-family: @font-family-bold;
    vertical-align: middle;
    margin-bottom: 10px;
  }
  .course-style {
    display: inline-block;
    padding: 3px 5px;
    margin: -3px 4px 0 0;
    color: @color-white;
    line-height: @font-size-small;
    font-size: @font-size-small;
    font-weight: @font-weight-normal;
    background-color: @color-big-highlight;
    border-radius: 2px 5px 2px 5px;
    vertical-align: middle;
  }
  .course-agreement {
    background-color: @color-blue;
  }
  .course-info {
    font-family: @font-family-regular;
    .icon-time {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 4px;
      background: url(../../assets/icon-time-gray.png) no-repeat 0 0 / cover;
    }
    .course-time,
    .course-num {
      font-size: @font-size-base;
      line-height: 12px;
      color: @color-grey;
    }
    .dot {
      padding: 0 4px;
      color: @color-grey;
    }
  }
  .teacher-avator {
    position: absolute;
    left: 20px;
    bottom: 24px;
  }
  .teacher-info {
    width: 52px;
    margin-right: 12px;
    dt {
      width: 52px;
      height: 52px;
      margin-bottom: 2px;
    }
    img {
      width: 52px;
      height: 52px;
      border-radius: @border-radius-circle;
      background-color: @color-small-white;
    }
    dd {
      width: 100%;
      text-align: center;
      font-size: @font-size-small;
      color: @color-grey;
      font-family: @font-family-regular;
    }
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: none;
    transition: all 0.2s linear;
    border-radius: @border-radius-normal;
  }
  .overdue {
    position: absolute;
    top: 70px;
    right: 32px;
    width: 108px;
    height: 108px;
    background: url(../../assets/overdue.png) no-repeat 0 0 / cover;
  }
}
.course-item {
  &:hover {
    transform: translate3d(0, -6px, 0);
  }
}
.course-item:hover {
  &:before {
    background-color: @color-highlight;
  }
}
.course-item-disabled {
  opacity: 0.6;
  &:hover {
    cursor: not-allowed;
  }
}
</style>

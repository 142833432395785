<template>
  <div class="content-area-container">
    <div class="tab-container">
      <TabContent />
    </div>
    <div class="list-container">
      <ul
        class="course-list-content zs_track_course_show"
        v-if="courseList && courseList.length"
      >
        <CourseItem
          v-for="course in courseList"
          :key="course.courseId"
          :course="course"
        />
      </ul>
      <Empty v-else />
    </div>

    <Pageation />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { zs } from "@zyb-data/stats-h5";

import CourseItem from "./CourseItem.vue";
import Empty from "./Empty.vue";
import Pageation from "./Page.vue";
import TabContent from "./TabContent.vue";

export default {
  computed: {
    ...mapGetters({
      isFinished: "isFinished",
      courseList: "courseList"
    })
  },
  mounted() {
    if (this.courseList.length) {
      zs.track("DNE_002");
    }
  },
  components: {
    CourseItem,
    Empty,
    Pageation,
    TabContent
  }
};
</script>
<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";

.content-area-container {
  flex-grow: 1;
  overflow: auto;
  background-color: @color-light-white;
}
.tab-container {
  z-index: 2;
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  background-color: @color-light-white;
  width: 100%;
}
.course-list-content,
.list-container {
  width: 1180px;
  margin: 82px auto 12px;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
/deep/ .el-loading-spinner {
  top: 300px !important;
}
</style>

<template>
  <div class="tab-content flex">
    <p
      v-for="item in tab"
      :key="item.value"
      :class="[{ active: isFinished == item.value }]"
      @click="handleChangeTab(item.value)"
    >
      {{ item.content }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { startLoading } from "@/utils/util";

export default {
  data() {
    return {
      tab: [
        {
          content: "进行中",
          value: 2
        },
        {
          content: "已结束",
          value: 1
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isFinished: "isFinished"
    })
  },
  methods: {
    ...mapActions({
      getCourseList: "getCourseList"
    }),
    ...mapMutations({
      setIsFinished: "SET_ISFINISHED",
      setIsLoading: "SET_ISLOADING",
      setPageNo: "SET_PAGENO"
    }),
    async handleChangeTab(value) {
      if (this.isFinished === value) {
        return;
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      startLoading(".list-container");
      this.setIsFinished(value);
      this.setIsLoading(false);
      this.setPageNo(1);
      await this.getCourseList({
        isFinished: value
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.tab-content {
  width: 1180px;
  height: 50px;
  margin: 12px auto 0px;
  background-color: @color-white;
  border-radius: @border-radius-normal;
  p {
    font-size: @font-size-base;
    color: @color-slight-black;
    line-height: 50px;
    margin: 0 8px 0 38px;
    text-align: center;
    cursor: pointer;
    font-family: @font-family-regular;
  }
}
.active {
  position: relative;
  font-weight: @font-weight-bold;
  color: @color-highlight;
  cursor: default;
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 42px;
    height: 4px;
    background-color: @color-highlight;
    border-radius: @border-radius-small;
  }
}
</style>

/**
 * store/modules/user
 */
import { endLoading } from "@/utils/util";

import * as APICOLLECTION from "@/api/my";
const MUTATION_SET_COURSEDATA = "SET_COURSEDATA";
const MUTATION_SET_PAGENO = "SET_PAGENO";
const MUTATION_SET_ISFINISHED = "SET_ISFINISHED";
const MUTATION_SET_ISLOADING = "SET_ISLOADING";

/**
 * state
 */
const state = {
  isFinished: 2, // 进行中和已完成标识，1已完成，2进行中
  pageSize: 20,
  pageNo: 1,
  total: 0,
  courseList: [],
  isLoading: false // 列表页面加载状态
};

/**
 * mutations
 */
const mutations = {
  [MUTATION_SET_COURSEDATA](state, data) {
    const { total, list } = data;
    state.total = total;
    state.courseList = list;
  },
  [MUTATION_SET_PAGENO](state, data) {
    state.pageNo = data;
  },
  [MUTATION_SET_ISFINISHED](state, data) {
    state.isFinished = data;
  },
  [MUTATION_SET_ISLOADING](state, data) {
    state.isLoading = data;
  }
};

/**
 * actions
 */
const actions = {
  /**
   * 获取课程列表
   */
  async getCourseList({ commit }, params = {}) {
    const { isFinished, pageSize, pageNo } = state;
    const obj = Object.assign(
      {
        isFinished,
        pageSize,
        pageNo
      },
      params
    );
    const res = await APICOLLECTION.getCourseList(obj);
    const { errNo, data } = res;
    if (!errNo) {
      commit(MUTATION_SET_COURSEDATA, data);
      state.isLoading = true;
      endLoading();
    } else {
      state.isLoading = false;
    }
  }
};

export default {
  state,
  mutations,
  actions
};

<template>
  <div class="link-container">
    <div class="link-content flex-h-c">
      <a href="javascript:;" :class="{ active: active }" @click="goPage"
        >我的课程</a
      >
      <i class="icon-arrow"></i>
      <a href="javascript:;" :class="{ active: !active }">课程列表</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false
    };
  },
  methods: {
    goPage() {
      this.active = true;
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.link-container {
  z-index: 3;
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  background-color: @color-light-white;
}
.link-content {
  width: 1180px;
  margin: 0 auto;
  padding: 24px 0;
  a {
    color: @color-slight-black;
    font-size: @font-size-base;
  }
  i {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 6px;
    background: url(../../assets/icon-disabled.png) no-repeat 0 0 / cover;
    transform: rotateY(180deg);
  }
  .active {
    color: @color-highlight;
  }
}
</style>

<template>
  <li
    class="lesson-item"
    :class="{ 'lesson-item-disabled': isDisabled }"
    :ref="lesson.latestLesson && 'watchEle'"
  >
    <div class="lesson-content flex-r-sb">
      <div class="lesson-rank" v-if="!lesson.isReopen">
        第{{ lesson.index }}章
      </div>
      <div
        class="lesson-right flex-c-sb"
        :class="{ 'lesson-right-padding': lesson.isReopen }"
      >
        <div class="lesson-part">
          <p class="lesson-name">{{ lesson.lessonName }}</p>
          <p class="lesson-info">
            <span v-if="lesson.latestLesson" class="latest-look">上次看到</span>
            {{ lessonText }}{{ lesson.lessonTime }}
            <span v-if="!isFreeCourse">{{ lessonComplete }}</span>
          </p>
        </div>
        <div
          class="lesson-btn"
          :class="getLessonStatus(lesson).className"
          @click="handleGoJump(lesson)"
        >
          <span class="icon-live" v-if="isLive"></span>
          {{ getLessonStatus(lesson).text }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { LESSONSTATUS_MAP, PLAYBACK_MAP } from "@/utils/constant";

export default {
  props: {
    lesson: {
      type: Object,
      default: () => {}
    },
    lessonStatusMap: {
      type: Object,
      default: () => {}
    },
    isFreeCourse: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    lessonComplete() {
      if (!this.lesson.lessonCompleteRate) {
        return ` · 未学习`;
      }
      return this.lesson.lessonCompleteRate >= 99
        ? " · 已学完"
        : ` · 已学习${this.lesson.lessonCompleteRate}%`;
    },
    lessonText() {
      if (
        !this.lesson.isReopen &&
        this.lesson.lessonStatus ===
          LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"]
      ) {
        return "直播中 · ";
      } else if (this.lesson.isReopen) {
        return "已重开 · ";
      } else {
        return "";
      }
    },
    isLive() {
      return (
        this.lesson.lessonStatus ===
          LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        this.lesson.playType === PLAYBACK_MAP["PLAYTYPE_LIVE"] &&
        !this.lesson.userLessonStatus
      );
    },
    isDisabled() {
      return (
        this.lesson.userLessonStatus ||
        this.lesson.isReopen ||
        this.lesson.lessonStatus ===
          LESSONSTATUS_MAP["LIVENOTSTART_LESSON_STATUS"] ||
        this.lesson.lessonStatus ===
          LESSONSTATUS_MAP["VIDEOCREATING_LESSON_STATUS"] ||
        this.lesson.lessonStatus ===
          LESSONSTATUS_MAP["NOPLAYBACK_LESSON_STATUS"]
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      const ele = this.$refs.watchEle;
      this.$emit("goScrollTop", ele);
    });
  },
  methods: {
    getLessonStatus(lesson) {
      /**
       * playType: 1直播，2伪直播，3录播
       * lessonStatus: 章节状态，1未开始，2直播中，3生成回放中，4回放已生成，5无回放
       * userLessonStatus: 1是已退款
       * isReopen: bool值是否重开
       * lessonStatus为1的时候，按钮状态‘未开始’
       * lessonStatus为2且playType为1，按钮状态叫‘进教室’
       * lessonStatus为2且playType为2或者3,按钮状态叫‘看视频’
       * lessonStatus为5，按钮状态叫‘无回放’
       * lessonStatus为3，回放生成中
       * lessonStatus为4且playType为1，看回放
       * lessonStatus为4且playType为2，看视频
       * lessonStatus为4且playType为3，看视频
       */
      const { lessonStatus, userLessonStatus, isReopen, playType } = lesson;
      let newLessonStatus = lessonStatus;
      // 看回放
      if (
        newLessonStatus === LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"] &&
        playType === PLAYBACK_MAP["PLAYTYPE_LIVE"]
      )
        newLessonStatus = LESSONSTATUS_MAP["PLAYBACK_LESSON_STATUS"];
      // 进教室
      if (
        newLessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        playType === PLAYBACK_MAP["PLAYTYPE_LIVE"]
      )
        newLessonStatus = LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"];
      // 看视频
      if (
        newLessonStatus === LESSONSTATUS_MAP["LIVEBROADCAST_LESSON_STATUS"] &&
        (playType === PLAYBACK_MAP["PLAYTYPE_PLAYBACK"] ||
          playType === PLAYBACK_MAP["PLAYTYPE_RECORDLESSON"])
      )
        newLessonStatus = LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"];
      if (
        newLessonStatus === LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"] &&
        (playType === PLAYBACK_MAP["PLAYTYPE_PLAYBACK"] ||
          playType === PLAYBACK_MAP["PLAYTYPE_RECORDLESSON"])
      )
        newLessonStatus = LESSONSTATUS_MAP["VIDEOCOMPLETE_LESSON_STATUS"];
      if (userLessonStatus)
        newLessonStatus = LESSONSTATUS_MAP["EXPIRED_LESSON_STATUS"];
      if (isReopen)
        newLessonStatus = LESSONSTATUS_MAP["REOPENED_LESSON_STATUS"];
      return this.lessonStatusMap[newLessonStatus];
    },
    handleGoJump(lesson) {
      this.$emit("jumpToNextPage", lesson);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.lesson-item {
  &:hover {
    background-color: @color-huge-white;
  }
  font-family: @font-family-regular;
}
.lesson-content {
  margin: 0 32px;
  padding: 24px 0;
  border-bottom: 1px solid @color-huge-white;
  .lesson-rank {
    min-width: 48px;
    flex-shrink: 0;
    margin-right: 30px;
    font-size: @font-size-large;
    color: @color-slight-black;
  }
  .lesson-right {
    flex-grow: 1;
  }
  .lesson-right-padding {
    padding-left: 78px;
  }
}
.lesson-part {
  .lesson-name {
    margin-right: 200px;
    font-size: @font-size-large;
    line-height: 24px;
    font-weight: @font-weight-bold;
    color: @color-light-black;
    font-family: @font-family-bold;
  }
  .lesson-info {
    margin-top: 8px;
    color: @color-xlight-gray;
    font-size: @font-size-base;
    line-height: 14px;
  }
  .icon-live {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(../../assets/live.gif) no-repeat 0 0 / cover;
  }
  .latest-look {
    padding: 1px 4px;
    margin-right: 8px;
    font-size: @font-size-small;
    color: @color-slight-highlight;
    background-color: rgba(255, 126, 60, 0.1);
    border-radius: @border-radius-small;
  }
}
.icon-live {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(../../assets/live.gif) no-repeat 0 0 / cover;
}
.lesson-btn {
  flex-shrink: 0;
  width: 100px;
  height: 36px;
  line-height: 36px;
  font-size: @font-size-base;
  text-align: center;
  border-radius: @border-radius-large;
  background-color: transparent;
  font-family: @font-family-bold;
  cursor: pointer;
  &.btn-active {
    color: @color-white;
    background: linear-gradient(153deg, #ff6a73 0%, #f5303c 100%);
  }
  &.btn-default {
    border: 1.5px solid @color-highlight;
    color: @color-highlight;
  }
  &.btn-inactive {
    color: @color-xlight-gray;
    background-color: @color-small-white;
  }
}
.lesson-item-disabled {
  .lesson-rank,
  .lesson-name,
  .lesson-info {
    color: @color-sslight-gray;
  }
  .lesson-btn {
    cursor: not-allowed;
  }
}
</style>

<template>
  <div class="course-list-container flex-c">
    <Header />
    <div class="course-list-box flex-c">
      <content-area :isLoading="isLoading" />
      <Footer />
    </div>
    <SideBar />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Header from "@/components/header/index.vue";
import Footer from "../components/footer.vue";
import ContentArea from "../components/list/ContentArea.vue";
import SideBar from "../components/SideBar.vue";

import { startLoading } from "@/utils/util";

export default {
  created() {
    startLoading();
    this.getCourseList();
  },
  components: {
    Header,
    Footer,
    ContentArea,
    SideBar
  },
  computed: {
    ...mapGetters({
      courseList: "courseList",
      isLoading: "isLoading"
    })
  },
  methods: {
    ...mapActions({
      getCourseList: "getCourseList"
    })
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/style/variables.less";
@import url("~@/assets/style/layout.less");

.course-list-container {
  min-height: 100vh;
}
.course-list-box {
  min-height: calc(100vh - 160px);
  flex-grow: 1;
  margin-top: 60px;
}
</style>

const getters = {
  getUid: state => state.my.uid,
  userInfo: state => state.my.userInfo,
  total: state => state.my.total,
  pageSize: state => state.my.pageSize,
  pageNo: state => state.my.pageNo,
  isFinished: state => state.my.isFinished,
  courseList: state => state.my.courseList,
  isLoading: state => state.my.isLoading
};

export default getters;
